<template>
  <div class="columns is-multiline">
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard">
      Productos Publicados
    </div>
    <div class="column is-12 separator"></div>
    <div class="tabs is-boxed column is-12">
      <ul>
        <li :class="{ 'is-active': tabActive === PENDDING }" @click="changeTab(PENDDING)"><a><span>Pendientes</span></a></li>
        <li :class="{ 'is-active': tabActive === ACTIVE }" @click="changeTab(ACTIVE)"><a><span>Activo</span></a></li>
        <li :class="{ 'is-active': tabActive === SOLD }" @click="changeTab(SOLD)"><a><span>Vendido</span></a></li>
        <li :class="{ 'is-active': tabActive === REMOVED }" @click="changeTab(REMOVED)"><a><span>Eliminado</span></a></li>
        <li :class="{ 'is-active': tabActive === REJECTED }" @click="changeTab(REJECTED)"><a><span>Rechazado</span></a></li>
      </ul>
    </div>
    <template v-if="products.length > 0">
      <div
        class="column is-4 productList"
        v-for="(product, index) of products"
        :key="index"
      >
        <product-card
          :productId="product.productId"
          :productName="product.name"
          :productImage="product.productImage"
          :productDesciption="product.description"
          :price="product.price"
          :productCondition="product.condition.toString()"
          :productStatus="product.status"
          :productStatusId="product.statusId"
          :showStatus="true"
        />
      </div>
    </template>
    <div class="column is-12"  v-if="products.length > 0">
      <base-pagination
        class="is-medium"
        :page="page"
        :pages="parseInt(totalProducts/6)"
        :action="paginator"
        :loading="loading"
      />
    </div>
    <div class="column is-12 has-text-centered is-empty" v-if="products.length === 0">
      El usuario no ha publicado productos
    </div>
  </div>
</template>

<script>
export default {
  components: {
    ProductCard: () => import('@/components/Products/ProductCard'),
    BasePagination: () => import('@/components/BasePagination')
  },
  props: {
    products: {
      type: Array
    },
    page: Number,
    totalProducts: Number,
    loading: Boolean,
    paginator: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      tabActive: 1,
      PENDDING: 1,
      ACTIVE: 2,
      SOLD: 3,
      REMOVED: 4,
      REJECTED: 5,
      pageOne: 1
    }
  },
  methods: {
    changeTab (status) {
      this.tabActive = status
      this.paginator(this.pageOne, status)
    }
  }
}
</script>

<style scoped>
  .is-empty {
    font-size: 12px;
  }
</style>
